import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';
import { getAddress, sendBtcTransaction  } from 'sats-connect';
import * as cbor from 'cbor-js';
import NogsGrid from './components/nogsgrid';
/* global BigInt */

function App() {
  // State to hold the SVG data
  const [svgImage, setSvgImage] = useState('hero.svg');
  // State for the name input by the user
  const [name, setName] = useState('');
  // State for the availability status
  const [isNameAvailable, setIsNameAvailable] = useState(null);
  // State for error handling
  const [statusMessage, setStatusMessage] = useState('Enter a ⌐◨-◨ name and see if it is available');
  const [statusMessageType, setStatusMessageType] = useState('info');
  const [txid, setTxid] = useState('');
  const [userAddresses, setUserAddresses] = useState({ ordinal: '', payment: '' });
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [useWallet, setUseWallet] = useState(false);
  const [manualAddress, setManualAddress] = useState('');
  const [showInscribeButton, setShowInscribeButton] = useState(true);
  const [showOrderStatusButton, setShowOrderStatusButton] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({ sats: 0, usd: 0, fundingAddress: '',timeToInscribe: '' });
  const [inscriptionID, setInscriptionID] = useState('');
  const [paymentDeadline, setPaymentDeadline] = useState('');



  function resetState() {
    setTxid('');
    setIsNameAvailable(null); // Reset availability status
    setInscriptionID(''); // Clear the transaction ID
    setPaymentDeadline('');
    setPaymentInfo({ sats: 0, usd: 0, fundingAddress: '',timeToInscribe: '' });
    setShowInscribeButton(true)
    setShowOrderStatusButton(false)

    // Add any other state variables that need to be reset
  }
  // Function to update the SVG image, you will call this when the user input generates a new SVG
  const updateSvgImage = (newSvgPath) => {
    setSvgImage(newSvgPath);
  };
  const payWithWallet = async () => {
    console.log(paymentInfo.fundingAddress, paymentInfo.sats)
    const sendBtcOptions = {
      payload: {
        network: {
          type: process.env.REACT_APP_BTCNETWORK, // or "Mainnet", depending on your application's needs
        },
        recipients: [
          {
            address: paymentInfo.fundingAddress, // The address to send BTC to
            amountSats: BigInt(paymentInfo.sats) // The amount in sats to send
          }
        ],
        senderAddress: userAddresses.payment, // This should be the sender's address; adjust as needed
      },
      onFinish: (response) => {
        alert("Transaction successful: " + JSON.stringify(response));
        checkOrderStatus();
      },
      onCancel: () => alert("Transaction canceled"),
    };
  
    try {
      await sendBtcTransaction(sendBtcOptions);
    } catch (error) {
      console.error('Error sending BTC transaction:', error);
      // Handle errors, for example, by showing an error message to the user
    }
  };
  
  const calculateDeadline = (timeToInscribe) => {
    // Get the current time
    const now = new Date();
    
    // Calculate the deadline by adding timeToInscribe to the current time
    // Assuming timeToInscribe is in milliseconds
    const deadline = new Date(now.getTime() + timeToInscribe);
  
    // Format the deadline as a local time string
    return deadline.toLocaleString();
  };
  const checkOrderStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_INSCRIBEURL}/orderstatus?orderID=${paymentInfo.fundingAddress}`);
      const data = response.data;
  
      // Check if the response includes inscriptionIDs
      if (data.inscriptionIDs && data.inscriptionIDs.length > 0) {
        setStatusMessage(`Successfully inscribed ${name}.⌐◨-◨`)
        setInscriptionID(data.inscriptionIDs[0]);
      } else {
        // If no inscriptionIDs are present, indicate that the status should be checked again later
        setStatusMessageType('info');
        setStatusMessage('Your order is still being processed (or you haven\'t paid). Please check again shortly.');
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
      setStatusMessage('Failed to fetch order status. Please try again.');
    }
  };
  // svgModifier.js
  const svgTob64 = async (svgURL) => {
    try {
      // Fetch the actual SVG blob using the URL
      const response = await fetch(svgURL);
      // Convert the blob to SVG text
      const svgText = await response.text();
  
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const svgElement = svgDoc.documentElement;
  
      // Update the first width and height attributes to 100%
      svgElement.setAttribute('width', '100%');
      svgElement.setAttribute('height', '100%');
  
      // Serialize the SVG back to a string
      const serializer = new XMLSerializer();
      const serializedSvg = serializer.serializeToString(svgElement);
  
      // Encode the string to base64
      const base64Svg = `${window.btoa(unescape(encodeURIComponent(serializedSvg)))}`;
  
      return base64Svg;
    } catch (error) {
      console.error("Failed to fetch or modify SVG:", error);
      return null; // Consider appropriate error handling
    }
  };
  

  const svgToPng = (svgUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      img.onload = () => {
        //console.log(img.width)
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngUrl = canvas.toDataURL('image/png');
        resolve(pngUrl.split(',')[1]); // Resolve the promise with the base64 part
      };
  
      img.onerror = reject; // Reject the promise on error
  
      img.src = svgUrl;
    });
  };

  const fetchFees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MEMPOOLURL}/api/v1/fees/recommended`);
      const { fastestFee } = response.data;
      console.log('using fee rate: '+fastestFee)
      return fastestFee;
    } catch (error) {
      console.error('Error fetching fastestFee:', error);
      // Handle the error appropriately - you might want to use a default fee rate here
      return null; // or a default fee value if you have one
    }
  };
  
  const handleInscribeClick = async () => {
    // Ensure there is an address to use for the inscription
    let addressToUse = ''
    const feeRate = await fetchFees();
    if(userAddresses.ordinal){
      addressToUse = userAddresses.ordinal;
    }
    else{
      addressToUse = manualAddress;
    }
    if (!addressToUse) {
      alert('Please connect your wallet or enter an address.');
      return;
    }
    let inscribeImg ='';

    inscribeImg = await svgToPng(svgImage);
    //inscribeImg = await svgTob64(svgImage);

    const metadata = {
      Collection: "⌐◨-◨ Names",
      Attributes: {
        Name: `${name}.⌐◨-◨`
      }
    };
    const encodedCbor = cbor.encode(metadata);
    const transmitCbor = Array.from(new Uint8Array(encodedCbor))
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
    // Define the data payload for the inscription request
    //console.log(inscribeImg)
    const inscriptionPayload = {
      mime: "image/png", // Assuming text for simplicity; adjust based on your data
      data: `${inscribeImg}`, // The data to inscribe, customized based on your application's logic
      address: addressToUse, // The address obtained either from wallet connection or manual input
      feeRate: feeRate.toString(), // The fee rate; adjust according to your requirements
      metaprotocol: '⌐◨-◨',
      metadata:transmitCbor,
      type: 'single',
      collection: 'nogglenames' 
    };
  
    // Make the POST request to the inscription endpoint
    try {
      const response = await axios.post(`${process.env.REACT_APP_INSCRIBEURL}/inscribe`, inscriptionPayload);
      // Handle the successful response
      //const { sats, usd, fundingAddress, timeToInscribe } = response.data;
      //console.log(`Successfully prepared inscription. Send ${sats} sats (${usd} USD) to ${fundingAddress}.`);

      setPaymentInfo({
        sats: response.data.sats,
        usd: response.data.usd,
        fundingAddress: response.data.fundingAddress,
        timeToInscribe: response.data.timeToInscribe
      });
      const timeMins = Number(response.data.timeToInscribe)/60000
      const deadlineString = calculateDeadline(response.data.timeToInscribe);
      setPaymentDeadline(deadlineString);
      setShowInscribeButton(false);
      setShowOrderStatusButton(true);
      setStatusMessage(`${name}.⌐◨-◨ is available to inscribe for ${response.data.sats} sats ($${response.data.usd})`)

      // Here, update your application state or UI with the response data
      // For example, show the user how to complete the payment, using the provided fundingAddress and the amount in sats
    } catch (error) {
      console.error('Error during inscription:', error);
      // Handle errors, for example, by showing an error message to the user
      alert('Failed to initiate the inscription. Please try again.');
    }
  };
  
  const handleConnectClick = async () => {
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'We need your address to connect your wallet for receiving Ordinals and payments.',
        network: {
          type: process.env.REACT_APP_BTCNETWORK,
        },
      },
      onFinish: (response) => {
        // Assuming the response includes an array of addresses
        const ordinalAddress = response.addresses.find(addr => addr.purpose === 'ordinals');
        const paymentAddress = response.addresses.find(addr => addr.purpose === 'payment');
        setUserAddresses({
          ordinal: ordinalAddress ? ordinalAddress.address : '',
          payment: paymentAddress ? paymentAddress.address : '',
        });
        setIsUserConnected(true);
        setUseWallet(true);
        setManualAddress(ordinalAddress ? ordinalAddress.address : ''); 
        setShowOrderStatusButton(false);
        setShowInscribeButton(true)
      },
      onCancel: () => {
        alert('Connection request canceled by user.');
      },
    };
    
  
    try {
      await getAddress(getAddressOptions);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
      // Optionally handle any errors (e.g., show an error message to the user)
    }
  };
  const handleInputChange = (e) => {
    const currentValue = e.target.value;
    // Check if the value includes spaces or periods, and remove them
    const filteredValue = currentValue.replace(/[\s.]/g, '');

    // Update the state only with the filtered value
    setName(filteredValue);
    if (statusMessage !== 'Enter a ⌐◨-◨ name and see if it is available.' && statusMessage !== 'Checking availability...') {
      setStatusMessage('Enter a ⌐◨-◨ name and see if it is available.');
      setStatusMessageType('info');
      setIsNameAvailable(null);
      setTxid(''); // Clear the txid as it's no longer relevant for the new search
    }
  };
  // Function to handle name search
  const handleNameSearch = async () => {
    if (!name.trim()) {
      setStatusMessage('Please enter a ⌐◨-◨ name to check its availability.');
      setStatusMessageType('error');
      return;
    }

    setStatusMessage(`Checking availability for "${name}"...`);
    setStatusMessageType('info');
    resetState();

    // Attempt to fetch the SVG based on the name first
    try {
      let searchName = name.toLowerCase();
      const svgResponse = await axios.get(`https://api.cloudnouns.com/v1/pfp?text=${encodeURIComponent(searchName)}.⌐◨-◨&size=32&background=0,1`, { responseType: 'blob' });
      // Create a URL for the blob to be used in an img src
      const svgUrl = URL.createObjectURL(svgResponse.data);
      updateSvgImage(svgUrl);
    } catch (svgError) {
      console.error('Error fetching the SVG:', svgError);
      // Handle the error, maybe set a default SVG or an error message
    }
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_INSCRIBEURL}/nogglename?name=${name}`);
      // Assuming a 200 status means the name is taken
      setIsNameAvailable(false);
      setTxid(response.data.txid); // Store the txid from the response
      setStatusMessage(`${name}.⌐◨-◨ is already inscribed.`);
      setStatusMessageType('error');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Name is available
        setIsNameAvailable(true);
        setStatusMessage(`${name}.⌐◨-◨ is available to inscribe.`);
        setStatusMessageType('success');
      } else {
        // General error handling
        setStatusMessage(`An error occurred while checking for ${name}.⌐◨-◨. Please try again.`);
        setStatusMessageType('error');
      }
    }
  };
  return (
    <div id="top">
      <div className="container">
        {/* Replace 'path-to-your-logo.png' with the actual image path */}
        {/*https://api.cloudnouns.com/v1/pfp?body=18&accessory=25&glasses=19&head=93&background=n&size=400 */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img src="nogs.png" alt="Logo" width="69" className="d-inline-block align-top" />
            </a>
            <div className="d-flex">
            <a href="https://x.com/ordinalnounswtf" target="blank" className="me-2" ><i className="fab fa-twitter"></i></a>
                <a href="https://discord.gg/U3SThRP4e9" className="me-2"><i className="fab fa-discord"></i></a>
              {!isUserConnected ? (
                <button className="btn btn-primary" onClick={handleConnectClick}>Connect</button>
              ) : (
                // Use a button or a stylized span for the connected state, making it clickable to reconnect
                <button className="btn btn-outline-secondary" onClick={handleConnectClick}>
                  {`Connected: ${userAddresses.ordinal.substring(0, 4)}...${userAddresses.ordinal.slice(-4)}`}
                </button>
              )}
              
            </div>
          </div>
        </nav>
        
        <div className="row mt-5 justify-content-center" id="fullForm">
          <div className="col-md-6">
            {/* Center the SVG image */}
            <div className="text-center">
              <img src={svgImage} alt="Dynamic SVG" className="img-fluid"style={{ borderRadius:"5px"}}/>
            </div>
            <h1 className="text mt-4">⌐◨-◨ Names</h1>
            <div className="mb-3">
              <div className="input-group">
                <input 
                  type="text" 
                  id="nameSearch" 
                  className="form-control text-end" 
                  placeholder="Enter name" 
                  value={name} 
                  onChange={handleInputChange}
                  onKeyDown={(event) => {if (event.key === 'Enter') {handleNameSearch();}}}
                />
                <span className="input-group-text">.⌐◨-◨</span>
                <button className="btn btn-primary" type="button" onClick={handleNameSearch}>Search</button>
              </div>
            {/* Status message displayed just below the input box */}
            {statusMessageType === 'error' && txid ? (
            <p className="text-danger mt-2">
              {`${name}.⌐◨-◨ is `}
              <a href={`https://ordinals.com/inscription/${txid}i0`} target="_blank">
                already inscribed.
              </a> Try another name.
            </p>
          ) : (
            <p className={`text-${statusMessageType} mt-2`}>{statusMessage}</p>
          )}
          </div>
          {isNameAvailable && !inscriptionID && <div className="mb-3">
          <h3>User Info</h3>
            <label htmlFor="addressInput" className="form-label">Taproot Address to receive your  ordinal</label>
            <input 
              type="text" 
              id="addressInput" 
              className="form-control" 
              placeholder="Enter a valid taproot address or connect your wallet" 
              value={manualAddress} 
              onChange={(e) => setManualAddress(e.target.value)}
              readOnly={isUserConnected || !showInscribeButton} // Make the field read-only if the user has connected their wallet
            />
          </div>}
          {isNameAvailable && showInscribeButton && (isUserConnected || manualAddress) && !inscriptionID && <button className="btn btn-success" onClick={handleInscribeClick}>Inscribe</button>}
          {isNameAvailable && showOrderStatusButton && !inscriptionID && (
            <>
              <div className="mb-3">
                <h3>Payment Info</h3>
                
                  <div className="input-group">
                    <input type="text" className="form-control" readOnly value={paymentInfo.fundingAddress} id="fundingAddressInput" />
                    <button className="btn btn-outline-secondary" type="button" onClick={() => {
                      navigator.clipboard.writeText(paymentInfo.fundingAddress).then(() => {
                        alert('Address copied to clipboard!');
                      }, (err) => {
                        console.error('Could not copy address: ', err);
                      });
                    }}>📋</button>
                  </div>
              
                <p>Please send {paymentInfo.sats} sats (${paymentInfo.usd}) to the above address to start your inscription or connect your wallet to pay from there.</p>
                {/* Display the calculated deadline */}
                <p>Complete your payment by: {paymentDeadline}</p>
              </div>
              <button className="btn btn-info" onClick={checkOrderStatus}>Check Order Status</button>
              {isUserConnected && <button className="btn btn-primary" id="walletBtn" onClick={payWithWallet}>Pay with Wallet</button>}
            </>
          )}
          {inscriptionID && <a className="btn btn-success" href={`${process.env.REACT_APP_MEMPOOLURL}/tx/${inscriptionID.slice(0, -2)}`} target="_blank" rel="noopener noreferrer">View Tx</a>}
          {inscriptionID && <a className="btn btn-success" id="ordBtn" href={`${process.env.REACT_APP_ORDINALURL}/inscription/${inscriptionID}`} target="_blank" rel="noopener noreferrer">View Ord</a>}
          </div>
        </div>
        <div className="App">
          <h1>Gallery</h1>
          <NogsGrid />
        </div>
      
      </div>
    </div>
    
  );
}

export default App;
