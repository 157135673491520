// Assuming this is in NogsGrid.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './nogsgrid.css'; // Assuming you have a CSS file for specific styles

const NogsGrid = () => {
    const [nogs, setNogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const fetchNogs = async (page) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_INSCRIBEURL}/index/nogs?page=${page}&limit=9&sortOrder=-1`);
            setNogs(response.data.documents);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching nogs:', error);
            // Handle error
        }
    };

    useEffect(() => {
        fetchNogs(currentPage);
    }, [currentPage]);

    return (
        <div className="nogs-grid-container mt-5">
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</button>
                    </li>
                    <li className="page-item disabled"><a className="page-link">Page {currentPage} of {totalPages}</a></li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</button>
                    </li>
                </ul>
            </nav>
            <div className="row">
                {nogs.map(nog => (
                    <div key={nog._id} className="col-md-4 mb-4">
                        <a href={`https://ordinals.com/inscription/${nog.txid}i0`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="nog-card p-3 h-100 border-0 shadow-sm" style={{ borderRadius: '10px' }}>
                                {nog.version === "2" && nog.pfp &&
                                        <div className="text-center">
                                            <img src={`data:image/png;base64,${nog.pfp}`} alt="Profile" className="pixelated-image" />
                                        </div>
                                    }
                                <p className="text-center nog-name">{nog.content}</p>
                                <p>{`Block: ${nog.block}`}</p>
                                {/*<p>{`Txid: ${nog.txid.substring(0, 6)}...`}</p>*/}
                                <p>{`${new Date(nog.blockTime * 1000).toLocaleDateString()}`}</p>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default NogsGrid;
